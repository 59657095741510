.qr-container-main .logo-wrapper img {
    width: 100px;
}

.qr-title {
    margin-top: 20px;
}

.wrapper-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.wrapper-side .form-container {
    width: 473px;
    padding: 20px;
    margin: auto;
}

.qr-container-main h1 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

.qr-container-main p {
    margin-bottom: 20px;
    font-size: 14px;
}

.qr-container-main a {
    color: var(--primary-color);
    text-decoration: none;
}

.qr-form {
    margin-top: 20px;
    width: 400px;
}

.qr-form button {
    margin-top: 10px;
}

.invalid-msg {
    text-align: center;
}

@media (max-width: 600px) {
    .qr-form,
    .wrapper-side,
    .wrapper-side .form-container {
        width: 100%;
        height: fit-content;
    }
}