.qr-transfer-cancel{
    margin-right: 10px;
}

.qr-transfer-cancel svg,
.qr-generate svg{
    cursor: pointer;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    padding: 5px;
    border: 2px solid #c8986b;
    border-radius: 7px;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.8);
}

.modal>.header {
    width: 100%;
    border-bottom: 1px solid gray;
    font-size: 18px;
    text-align: center;
    padding: 5px;
}

.modal>.content {
    width: 100%;
    padding: 10px 5px;
}

.modal>.actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
    margin-top: 50px;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 3px solid #a87c53;
    color: #a87c53;
}

.modal.qrmodal .content{
    text-align: center;
}