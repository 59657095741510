.reward-history-comments {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
}

.reward-history-comments h2 {
    text-align: center;
    margin-bottom: 20px;
}

.reward-history-comments form {
    display: flex;
    flex-direction: column;
}

.reward-history-comments label {
    margin-bottom: 10px;
    font-weight: bold;
}

.reward-history-comments textarea {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    height: unset !important;
}

.reward-history-comments .comment-section {
    display: flex;
    flex-direction: column;
}

.reward-history-comments .all-comments {
    height: calc(100vh - 300px);
    overflow: scroll;
}

.reward-history-comments .comments {
    margin-top: 20px;
}

.reward-history-comments .comment {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 20px;
}

.reward-history-comments .comment .child-comment{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border: 1px solid #c8986b;
    width: 97%;
    padding: 7px;
    border-radius: 4px;
}

.reward-history-comments .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.reward-history-comments .comment-content {
    flex-grow: 1;
}

.reward-history-comments .comment-author {
    font-weight: bold;
    margin: 0;
}

.reward-history-comments .comment-date {
    font-size: 0.9em;
    color: #6c757d;
    margin: 5px 0;
}

.reward-history-comments .comment-text {
    margin: 10px 0;
}

.reward-history-comments .star-rating label {
    cursor: pointer;
    margin-right: 10px;
}

.reward-history-comments .star {
    font-size: 40px;
    cursor: pointer;
}

.reward-history-comments .star label:before{
    content: "☆ ";
}

.reward-history-comments .star.filled{
    color: #cb7f29;
}

.reward-history-comments .star.filled label:before{
    content: "★ ";
}