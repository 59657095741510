:root {
    --primary-color: #c8986b;
    --primary-color-v2: #b5a698;
    --primary-color-v3: #cb7f29;
    --primary-color-v4: #fad3af;
    --primary-color-v5: #ebd8c7;
    --secondary-color: #361400;
    --error-msg-color: rgba(255, 51, 0, 0.966);
    --success-msg-color: rgba(32, 192, 0, 0.966);
    --sidebar-width: 250px;
    --mob-header-height: 60px;
    --desk-header-height: 52px;
    --desk-header-margin: 30px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Nunito Sans", sans-serif;
}

body {
    font-family: "Nunito Sans", sans-serif;
    overflow: hidden;
}

/* custom scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background-color: #ffe5cc;
}

::-webkit-scrollbar-thumb {
    background-color: #ffcd9e;
}

.error {
    color: var(--error-msg-color);
}

.succes {
    color: var(--success-msg-color);
}

.dx-lib.input-error {
    margin-top: 10px;
    font-size: 12px;
    color: var(--error-msg-color);
}

.dx-lib-input {
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #c8986a;
    border-radius: 5px;
}

.dx-lib-input:focus {
    outline: 1px solid #c8986a;
}

.dx-lib-input::placeholder {
    color: #b3b3b3;
}

.dx-btn-primary {
    width: 100%;
    height: 45px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.dx-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 20px;
    line-height: normal;
}

.dx-btn.dx-close-btn {
    color: var(--primary-color);
}

.dx-close-btn>* {
    font-size: 36px;
}

.btn-link {
    border: none;
    background-color: transparent;
    color: var(--primary-color);
    cursor: pointer;
    text-decoration: none;
}

.dx-btn:hover,
.dx-btn:focus,
.btn-link:hover,
.btn-link:focus {
    border: none;
    background-color: transparent;
    outline: none;
}

.address-field {
    min-height: 100px;
    resize: none;
}

/* layout */
.sidebar {
    width: var(--sidebar-width);
    background-color: var(--primary-color);
}

.sidebar-section-title {
    color: var(--secondary-color);
}

.sidebar li *,
.sidebar li {
    color: var(--secondary-color);
}

.sidebar li a {
    width: 100%;
    height: 100%;
}

.sidebar li:hover {
    background-color: var(--primary-color-v3);
    color: #fff;
}

.sidebar li:hover * {
    color: #fff;
}

.content-main-container {
    width: calc(100vw - var(--sidebar-width));
    height: calc(100vh - var(--desk-header-height) - 20px);
    float: right;
}

.dx-btn,
.items {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.upload-btn {
    color: var(--primary-color-v3);
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 5px 10px;
    cursor: pointer;
}

.dx-primary-btn {
    background: var(--primary-color-v3);
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    text-decoration: none;
    cursor: pointer;
}

a.submit-button.dx-btn-primary {
    width: 100%;
    display: block;
    text-align: center;
    color: #fff;
}

/* profile */
.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    object-fit: cover;
    background-position: center;
    flex-direction: column;
}

.dx-label {
    font-size: 16px;
    font-weight: 700;
}

/* header logo */
.header-logo {
    width: 80px;
}

.logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-header-user-name {
    color: #fff;
    font-size: 16px;
}

.desktop-header-user-name a{
    color: #fff;
    text-decoration: none;
}

/* mobile header */
.mobile-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: var(--mob-header-height);
    background-color: #f3f3f3;
}

/* desktop header */
.desktop-header-container {
    background: var(--primary-color-v3);
    width: calc(100vw - var(--sidebar-width) - var(--desk-header-margin));
    float: right;
    margin-right: calc(var(--desk-header-margin) / 2);
    margin-top: 7px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    align-items: center;
}

/* custom table */
.dx-table {
    display: flex;
    flex-direction: column;
}

.dx-table .dx-th {
    flex: 1;
}

.dx-table .dx-tr {
    display: flex;
    width: 100%;
}

.dx-table .dx-tr.table-header {
    display: flex;
    width: 100%;
    background: var(--primary-color-v3);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: #fff;
    position: sticky;
    top: 0;
}

.dx-table .dx-td {
    flex: 1;
}

.dx-table .dx-tr.table-data {
    background-color: #dfe0df;
    border-radius: 10px;
    margin-top: 10px;
}

.dx-table .dx-th,
.dx-table .dx-td {
    padding: 15px;
}

.profile-pic-sm-w {
    width: 35px;
    height: 35px;
    overflow: hidden;
    border-radius: 50px;
}

.profile-pic-sm-w img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-position: center;
    cursor: pointer;
}

.current-page-title {
    font-size: 20px;
    font-weight: 800;
    color: #fff;
}

.dx-table-responsive {
    overflow: auto;
    width: 100%;
    margin-bottom: 50px;
    padding: 0 13px;
}

.dx-table {
    width: -moz-fit-content;
    width: 100%;
    height: 100%;
    overflow: auto;
}


.dx-table-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.view-all {
    font-size: 13px;
    color: #cb7f29;
}

.table-desc-text {
    font-size: 13px;
    color: #858585;
    padding: 0 20px;
}

.points-wrapper {
    padding: 0 20px;
}

/* single page table */
.business-table-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
}

table.business-table {
    text-align: left;
    margin-top: 20px;
    border-spacing: 0 6px;

}

thead.table-header tr {
    background: var(--primary-color-v3);
    color: #fff;
}

tbody.table-body tr td,
thead.table-header tr th {
    padding: 20px;
}

tbody.table-body tr td {
    background: #dddddd;
    text-wrap: nowrap;
}

.pagination-controls {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    gap: 12px;
}

.pagination-button:disabled{
    background: #ddd;
    color: #555;
    cursor: not-allowed;
}

.pagination-button {
    background: #cb7f29;
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.pagination-info {
    color: #c8986a;
    font-size: 14px;
}

.search-input {
    width: 200px;
    border: 1px solid #ccc;
    height: 45px;
    padding: 10px;
}

.searchbox {
    position: relative;
}

.searchbox .close-icon svg {
    width: 17px;
}

.searchbox .close-icon {
    position: relative;
    right: 30px;
}

.searchbox svg {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.searchbox input[type="text"] {
    width: 100%;
    height: 46px;
    padding-left: 15px;
    outline: none !important;
    border: 1px solid #c5c8cf !important;
}

.searchbox input[type="text"]:focus,
.searchbox input[type="text"]:hover {
    border: 1px solid #c5c8cf !important;
}

/* single page table end */

.header-user-name {
    color: #fff;
    font-style: italic;
    font-weight: 400;
}

.not-found-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;
}

@media (min-width: 600px) {
    .dx-lib-input {
        min-width: 400px;
    }

    .close-btn {
        display: none;
    }

    .mobile-header-container {
        display: none;
    }

    .mob-page-title {
        display: none;
    }
}

@media (max-width: 600px) {
    .desktop-header-container {
        display: none;
    }

    .list-wrapper-1,
    .list-wrapper-3 {
        min-height: 400px;
    }

    .list-wrapper-2 {
        min-height: 113px;
    }

    .list-wrapper-4 {
        min-height: 430px;
    }

    .dashboard-grid-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .content-main-container {
        width: 100vw;
        overflow: auto;
        float: none;
        height: 100%;
    }

    .points-wrapper {
        font-size: 24px;
        margin-top: 4px;
        font-weight: 700;
        line-height: normal;
    }

    .grid-child {
        padding: 18px;
    }

    .dx-table-responsive {
        overflow: auto;
        width: -moz-fit-content;
        width: fit-content;
        height: 331px;
    }

    .list-wrapper-1 .dx-table-responsive,
    .list-wrapper-1 .dashboard-table {
        width: 100%;
    }

    .user-profile-update {
        padding: 40px;
        margin-left: 0px;
    }

    .form,
    .profile-picture-container,
    .user-profile-update label {
        width: 100%;
    }
}