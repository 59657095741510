.user-profile-update {
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.user-profile-update h2 {
    text-align: center;
    margin-bottom: 20px;
}

.user-profile-update form {
    display: flex;
    flex-direction: column;
}

.user-profile-update label {
    margin-bottom: 10px;
    font-weight: bold;
}

.user-profile-update input,
.user-profile-update textarea {
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.user-profile-update input:read-only {
    border: 1px solid #dadada;
    color: #afafaf;
}

.profile-picture-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
    margin-bottom: 20px;
}