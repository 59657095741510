:root {
    --primary-color: #c8986a;
}

.auth-container-main .logo-wrapper img {
    width: 100px;
}

.auth-title {
    margin-top: 20px;
}

.forgor-psw-wrapper {
    width: 100%;
    margin-top: 5px;
    text-align: right;
    font-size: 13px;
}

.signup-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    color: #000;
    font-family: Arial, sans-serif;
    height: 100vh;
}

.wrapper-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
}

.wrapper-side .form-container {
    width: 473px;
    padding: 20px;
    margin: auto;
}

.auth-banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auth-container-main h1 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: bold;
}

.auth-container-main p {
    margin-bottom: 20px;
    font-size: 14px;
}

.auth-container-main a {
    color: var(--primary-color);
    text-decoration: none;
}

.form-group-dx {
    margin-bottom: 20px;
    width: 100%;
}

.form-group-dx label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
}

.form-group-dx input,
.form-group-dx select {
    width: 100%;
    height: 45px;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
}

.form-group-dx select {
    background-color: white;
}

.form-group-dx input:focus,
.form-group-dx select:focus {
    outline: 1px solid var(--primary-color);
}

.form-group-dx input::placeholder {
    color: #b3b3b3;
}

.password-label-wrapper {
    width: 100%;
    display: flex;
}

.password-label-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle-password {
    margin-left: 10px;
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
}

.password-hint {
    margin-top: 5px;
    font-size: 12px;
    color: #b3b3b3;
}

.submit-button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #a87c53;
}

.account-exist-check {
    margin-top: 10px;
}

.auth-form {
    margin-top: 20px;
    width: 400px;
}

.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.resend-otp {
    margin-top: 10px;
    font-size: 14px;
}

.form-group-country-mobile .form-group-country{
    width: 39%;
    float: left;
}

.form-group-country-mobile .form-group-mobile{
    width: 60%;
    float: right;
}

.auth-form.signup button{
    margin-top: 10px;
}

@media (max-width: 600px) {
    .signup-container {
        justify-content: flex-start;
        flex-direction: column;
    }

    .auth-form,
    .wrapper-side,
    .wrapper-side .form-container {
        width: 100%;
        height: fit-content;
    }

    .auth-banner-img {
        height: 180px;
    }
}