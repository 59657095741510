.sidebar {
    height: 100vh;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s ease;
    z-index: 1000;
}

.sidebar.closed {
    transform: translateX(-100%);
}

.sidebar.open {
    transform: translateX(0);
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sidebar-header h3 {
    margin: 0;
}

.close-btn {
    cursor: pointer;
}

.sidebar-section {
    margin-top: 20px;
}

.sidebar-section-title {
    font-size: 14px;
    margin-bottom: 10px;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar li {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
}

.sidebar li * {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #ffffff;
}

.sidebar li:hover {
    border-radius: 5px;
}

.sidebar-icon {
    margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .sidebar {
        width: 200px;
    }

    .sidebar-header h3 {
        font-size: 18px;
    }

    .sidebar li {
        font-size: 14px;
    }

    .sidebar-icon {
        margin-right: 8px;
    }
}